import React from "react";
import Navbarr from "../Components/Navbarr";
import "../assets/css/PrivacyFirst.css";
import PrivacySect from "../Components/PrivacySect";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const Navigate = useNavigate();
  return (
    <div className="privacyPolicy">
      <Navbarr />
      <div className="privacyFirstCont">
        <div className="privacyFirstCont1">
          <p>Privacy</p>
          <h1>NetGuardian Privacy Policy</h1>
        </div>
      </div>
      <div className="FirstPC">
        <div className="privacyFirstContent">
          <h2>Effective Date: 11/13/2023</h2>
          <p>
            Welcome to NetGuardian. We are committed to protecting your privacy
            and ensuring a positive experience on our website and services. This
            Privacy Policy outlines our practices regarding the collection, use,
            and protection of your information.
          </p>

          <h2>Information Collection</h2>
          <p>
            <strong>No Data Collection Policy:</strong> We firmly believe in the
            sanctity of your online privacy. Our service is designed to ensure
            your anonymity and protect your personal information. To this end,
            we do not collect, store, or track any personal data, including
            browsing history, traffic destination, data content, or DNS queries.
          </p>
          <p>
            <strong>No Cookies Policy:</strong> Our website does not use cookies
            or any similar tracking technologies. Your online activities and
            preferences are not monitored, recorded, or analyzed in any form.
          </p>

          <h2>Data Use</h2>
          <p>
            <strong>No Data Use:</strong>In line with our no data collection
            policy, we do not use any user data simply because we do not collect
            any. Your online activities remain entirely private and unknown to
            us.
          </p>
          <p>
            <strong>Legal Compliance:</strong> While we prioritize user privacy,
            we will comply with any legal requirements, such as court orders or
            subpoenas. However, given our no data collection policy, we
            typically have no data to provide in response to such legal
            requests.
          </p>

          <h2>Data Security</h2>
          <p>
            <strong>Security Measures:</strong> We employ robust security
            measures to protect our website and services. These measures are
            designed to provide a safe and secure online environment for our
            users.
          </p>
          <p>
            <strong>No Logs Policy:</strong> Our strict no-logs policy means
            that we do not keep any record of your online activities. Your
            anonymity and privacy are paramount to us.
          </p>

          <h2>User Rights</h2>
          <p>
            <strong>Transparency:</strong> Users have the right to understand
            how their data is handled. Given that we do not collect data, this
            translates into our commitment to maintaining a transparent no data
            collection policy.
          </p>

          <p>Contact us for any privacy-related inquiries.</p>

          <h2>Policy Changes</h2>
          <p>
            We reserve the right to modify this privacy policy at any time.
            Changes will be effective immediately upon posting to our website,
            and we will make all reasonable efforts to notify users of
            significant changes.
          </p>

          <p>
            Discover more about our commitment to privacy and protect yourself
            online.
          </p>
          <div className="privacyFirstBtn">
            <button
              className="firstPrivacyfbtn"
              onClick={() => Navigate("/sign-in")}
            >
              Protect My Privacy!
            </button>
            <button onClick={() => Navigate("/vpn-featuures")}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <PrivacySect />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
