import React from 'react'
import "../assets/css/vpnDownload.css"
import Navbarr from '../Components/Navbarr'
import androidIcon from "../assets/Images/androidIcon.png"
import iosIcon from "../assets/Images/iosIcon.png"
import windowIcon from "../assets/Images/windowIcon.png"
import PricingSection from '../Components/PricingSection'
import Footer from '../Components/Footer'
import PrivacySect from '../Components/PrivacySect'

const VpnDownload = () => {
    return (
        <div className='vpnDownload'>
            <Navbarr />
            <div className='vpndownCont'>
                <div className='downlCont'>
                    <h1>VPN Downloads</h1>
                    <p>Download the vpn for your operating system here</p>
                    <div className='downloadbtn'>
                        <button>
                            <img src={androidIcon} alt='icon' />
                            Download  For  Android
                        </button>
                        <button>
                            <img src={iosIcon} alt='icon' />
                            Download  For  IOS
                        </button>
                        <button>
                            <img src={windowIcon} alt='icon' />
                            Download  For  Windows
                        </button>
                    </div>
                </div>
            </div>
            <PricingSection />
            <PrivacySect />
            <Footer />
        </div>
    )
}

export default VpnDownload