import React from "react";
import { PricingCardData } from "../Pages/Data";
import "../assets/css/pricing.css";
const PricingSection = () => {
  return (
    <div className="pricingSect">
      <h1>Pricing</h1>
      <div className="pricingCards">
        {PricingCardData.map((item, index) => (
          <div className="pricingcard" key={index}>
            <div className="priceCardTop">
              <img src={item.img} alt="pricing card icon" />
              <div className="pricecardTopCont">
                <p>{item.duration}</p>
                {item.name ? <h1>{item.name}</h1> : ""}
              </div>
              {item.btn ? (
                <button className="pricecardbtn">Popular</button>
              ) : (
                ""
              )}
            </div>
            <p className="pricePara">{item.para}</p>
            <h1 className="priceRate">
              {item.price} <span>{item.span}</span>
            </h1>
            <h2 className="priceCardInclude">{item.include}</h2>
            {item.specification.map((item, index) => (
              <div className="cardbottomCont" key={index}>
                <div className="cardcont1">
                  <img src={item.img} alt="correct icon" />
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
            <div className="pricebtn2">
              <button>Get Started</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSection;
