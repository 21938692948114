import React from "react";
import Navbarr from "../Components/Navbarr";
import "../assets/css/PrivacyFirst.css";
import PrivacySect from "../Components/PrivacySect";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const Navigate = useNavigate();
  return (
    <div className="privacyPolicy">
      <Navbarr />
      <div className="privacyFirstCont">
        <div className="privacyFirstCont1">
          <p>Terms of Service</p>
          <h1>NetGuardian Terms of Service</h1>
        </div>
      </div>
      <div className="FirstPC">
        <div className="privacyFirstContent">
          <h2>Effective Date: 11/13/2023</h2>
          <p>
            Welcome to NetGuardian. We are committed to protecting your privacy
            and ensuring a positive experience on our website and services. This
            Terms of Service governs your usage of our website and services. By
            using our website or services, you agree to be bound by these terms
            of service.
          </p>

          <h2>Acceptance</h2>
          <p>
            <strong>Acceptance of Terms:</strong> By accessing or using the
            NetGuardian website and VPN application ("Service"), you agree to be
            bound by these Terms of Service ("Terms"). If you do not agree to
            these Terms, you may not use the Service.
          </p>
          <p>
            <strong>Changes to Terms:</strong> We reserve the right to revise
            and update these Terms at any time. Your continued use of the
            Service following the posting of changes will mean you accept those
            changes.
          </p>

          <h2>Use of the Service</h2>
          <p>
            <strong>Legal Use:</strong>You agree to use the Service only for
            lawful purposes and in a way that does not infringe the rights of,
            restrict, or inhibit anyone else's use and enjoyment of the Service.
            The Service shall not be used for any illegal or unauthorized
            purpose.
          </p>
          <p>
            <strong>Prohibited Activities:</strong> You are prohibited from
            using the Service to engage in any activity that is illegal,
            harmful, or interferes with anyone's use of the Service. This
            includes, but is not limited to: - Illegal activities, including but
            not limited to fraud, trafficking in obscene material, drug dealing,
            and gambling offenses. - Interfering with or disrupting the Service
            or servers or networks connected to the Service.
          </p>

          <h2>Compliance</h2>
          <p>
            <strong>Compliance with Laws:</strong> You are responsible for
            ensuring that your use of the Service complies with all applicable
            laws and regulations.
          </p>
          <p>
            <strong> No Warranty:</strong> The Service is provided "as is"
            without any warranties, express or implied. NetGuardian VPN does not
            guarantee the availability, accuracy, completeness, reliability, or
            timeliness of the Service.
          </p>

          <h2> Limitation of Liability</h2>
          <p>
            <strong> Limitation of Liability:</strong> NetGuardian shall not be
            liable for any direct, indirect, incidental, special, consequential,
            or exemplary damages resulting from your use or inability to use the
            Service.
          </p>

          <h2>Indemnification</h2>
          <p>
            You agree to defend, indemnify, and hold harmless NetGuardian VPN,
            its affiliates, officers, directors, employees, and agents from any
            claims, damages, liabilities, costs, and expenses arising from your
            use of the Service or your violation of these Terms.
          </p>

          <h2>User Responsibility</h2>
          <p>
            You are responsible for all activities that occur under your account
            and for ensuring that your use of the Service complies with these
            Terms.
          </p>
          <h2>Termination</h2>
          <p>
            NetGuardian VPN reserves the right to terminate or suspend your
            access to the Service without prior notice for any breach of these
            Terms.
          </p>
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of BVI.
          </p>
          <h2>Contact Information</h2>
          <p>For any questions about these Terms, please contact us.</p>
          <h2>Acknowledgement</h2>
          <p>
            By using the Service, you acknowledge that you have read,
            understood, and agree to be bound by these Terms.
          </p>

          <div className="privacyFirstBtn">
            <button
              className="firstPrivacyfbtn"
              onClick={() => Navigate("/sign-in")}
            >
              Protect My Privacy!
            </button>
            <button onClick={() => Navigate("/vpn-featuures")}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <PrivacySect />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
