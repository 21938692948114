import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/Images/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import "../assets/css/navbar.css";
import axios from "axios";

const Navbarr = () => {
  const Navigate = useNavigate();
  const [active, isActive] = useState();
  const [scrolledOnce, setScrolledOnce] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [ipData, setIpData] = useState({ ip: "", isp: "" });
  const MenuHandler = () => {
    isActive(!active);
  };
  const cutHandler = () => {
    isActive(false);
  };
  const navbarRef = useRef();

  useEffect(() => {
    axios
      .get("https://httpbin.org/ip")
      .then((response) => {
        setIpAddress(response.data.origin);
      })
      .catch((error) => {
        console.error("Error fetching IP address", error);
      });

    axios
      .get("https://ipinfo.io?token=bbac37ca1b190e")
      .then((response) => {
        setIpData({
          ip: response.data.ip,
          isp: response.data.org,
        });
           console.log(response);
      })
      .catch((error) => {
          console.error("Error fetching IP information", error);
      });

    const handleScroll = () => {
      const navbarPosition = navbarRef.current.offsetTop;

      // Check if it's the first scroll
      if (!scrolledOnce && window.scrollY > navbarPosition) {
        setScrolledOnce(true);
      }

      // Update scrolling state based on the first scroll
      setIsScrolled(scrolledOnce && window.scrollY > navbarPosition);
    };

    const handleResize = () => {
      // Recalculate position on window resize
      handleScroll();
    };

    handleScroll(); // Initial calculation

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [scrolledOnce]);

  const navMenu = [
    {
      Name: "What’s a VPN?",
      path: "/",
    },
    {
      Name: "Features",
      path: "/vpn-features",
    },
    {
      Name: "Downloads",
      path: "/vpn-download1",
    },
    {
      Name: "Privacy First!",
      path: "/privacy-first",
    },
  ];
  return (
    <div className={`navbar ${isScrolled ? "scrolled" : ""}`} ref={navbarRef}>
      <div className="navTop">
        <p>
          Your IP ADDRESS: {ipAddress} YOUR ISP:
          {ipData.isp || "Fetching ISP..."} | and STATUS: <div className="">Unprotected</div>
        </p>
      </div>
      <div className="navBottom">
        <div className="navlogo">
          <img src={Logo} alt="logoImage" />
        </div>
        <div className={`navRight ${active ? "open" : ""}`}>
          <h1 className="cutbtn" onClick={cutHandler}>
            X
          </h1>
          <div className="navLinks">
            {navMenu.map((item, index) => (
              <NavLink key={index} className="navmenuLink" to={item.path}>
                {item.Name}
              </NavLink>
            ))}
          </div>
          <div className="navbtn">
            <button className="navbtn1" onClick={() => Navigate("/signup")}>
              Sign Up
            </button>
            <button className="navbtn2" onClick={() => Navigate("signup")}>
              Get Started
            </button>
          </div>
        </div>
        <i class="bx bx-menu menuIcon" onClick={MenuHandler}></i>
      </div>
    </div>
  );
};

export default Navbarr;
