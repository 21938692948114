import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Navbarr from "./Components/Navbarr";
import VpnDownload from "./Pages/VpnDownload";
import PrivacyFirst from "./Pages/PrivacyFirst";
import VpnFeatures from "./Pages/VpnFeatures";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import VpnDownload1 from "./Pages/VpnDownload1";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/vpn-download" Component={VpnDownload} />
        <Route path="/vpn-download1" Component={VpnDownload1} />
        <Route path="/privacy-first" Component={PrivacyFirst} />
        <Route path="/vpn-features" Component={VpnFeatures} />
        <Route path="/login" Component={Login} />
        <Route path="/signup" Component={SignUp} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
        <Route path="/terms-of-service" Component={TermsOfService} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
