import serviceImg from "../assets/Images/cloud.png";
import pricingcardImg from "../assets/Images/18px.png";
import pricingcardImg1 from "../assets/Images/18px (1).png";
import checkIcon from "../assets/Images/Check Circle.png";
import checkIcon1 from "../assets/Images/Check Circle1.png";

const HomeServiceData = [
  {
    img: serviceImg,
    name: "Whats a VPN?",
    para: " It's like a protective bubble. Once you turn on our VPN, it wraps your internet connection in a layer of powerful encryption. This means everything you do online is turned into a private code — think of it as a secret language that only you understand.",
  },
  {
    img: serviceImg,
    name: "IP Anonymity",
    para: "Your IP address identifies you on the web. As soon as you connect to one of our global servers, your IP address is replaced with one from our system. This not only keeps your location and identity secret but also allows you to access the internet as if you were in a different place — ideal for streaming and browsing without limits.",
  },
  {
    img: serviceImg,
    name: "Protected Browsing",
    para: "It's technology made simple — one click, and you're invisible to hackers, immune to trackers, and free from content restrictions. With our VPN, you're not just browsing; you're fortifying your digital life.",
  },
  {
    img: serviceImg,
    name: "Ad Blocker",
    para: "NetGuardian VPN's built-in ad blocker delivers an uninterrupted and serene online experience by expertly filtering out annoying ads. Enjoy a cleaner, faster browsing experience without the distraction of unwanted advertisements.",
  },
  {
    img: serviceImg,
    name: "Superior Encryption",
    para: "With NetGuardian, expect top-tier encryption that doesn't slow you down. We balance high-level security with speed and ease of use, ensuring a smooth online experience. Our goal is to make advanced protection accessible and affordable, because we believe everyone has the right to online safety.",
  },
  {
    img: serviceImg,
    name: "Privacy Commitment",
    para: "Choose NetGuardian for a guarantee of 100% privacy. Our strict no-logs policy means your data is never stored, tracked, or sold. We're committed to upholding your confidentiality, providing a safe online environment where your personal information remains just that - personal.",
  },
];

const PricingCardData = [
  {
    img: pricingcardImg,
    duration: "Pay Monthly",
    name: "Basic",
    para: "Pay as you go! ",
    price: "$9.99",
    span: "/monthly",
    include: "What’s included",
    specification: [
      {
        img: checkIcon,
        para: "5 devices",
      },
      {
        img: checkIcon,
        para: "70+ Countries",
      },
      {
        img: checkIcon,
        para: "Secure Servers",
      },
      {
        img: checkIcon,
        para: "Unlimited Data",
      },
    ],
  },
  {
    img: pricingcardImg1,
    duration: "Pay Annually",
    para: "The best bang for your buck - save 20% per year and stay protected. ",
    price: "$7.99",
    btn: "Popular",
    span: "/monthly",
    include: "What’s included",
    specification: [
      {
        img: checkIcon1,
        para: "5 devices",
      },
      {
        img: checkIcon1,
        para: "70+ Countries",
      },
      {
        img: checkIcon1,
        para: "Secure Servers",
      },
      {
        img: checkIcon1,
        para: "Unlimited Data",
      },
    ],
  },
];

const FeaturesServiceData = [
  {
    img: serviceImg,
    name: "AES-256 Encryption",
    para: "We use advanced algorithms to encrypt data transfers, keeping your data safe from prying eyes.",
  },
  {
    img: serviceImg,
    name: "No-Logs Policy",
    para: "We don't believe in storing or selling your data, so we don't keep track of anything you do.",
  },
  {
    img: serviceImg,
    name: "Kill Switch",
    para: "Automatically disconnects you from the internet if the VPN connection drops.",
  },
  {
    img: serviceImg,
    name: "IP Leak Guard",
    para: "Protects against DNS, IPv6, and WebRTC leaks that could expose your real IP address.",
  },
  {
    img: serviceImg,
    name: "Increase Browsing Speed",
    para: "Boosts speed by blocking ads and trackers, thus reducing load times.",
  },
  {
    img: serviceImg,
    name: "Worldwide Servers",
    para: "We offer more than 100 servers in 70+ countries, so with NetGuardian, you're global.",
  },
];

const FeaturesServiceData1 = [
  {
    img: serviceImg,
    name: "User-Friendly Interface",
    para: "We use advanced algorithms to encrypt data transfers, keeping your data safe from prying eyes.",
  },
  {
    img: serviceImg,
    name: "User-Friendly Interface",
    para: "We don't believe in storing or selling your data, so we don't keep track of anything you do.",
  },
  {
    img: serviceImg,
    name: "Simultaneous Connections",
    para: "Automatically disconnects you from the internet if the VPN connection drops.",
  },
  {
    img: serviceImg,
    name: "Ad & Malware Blocking",
    para: "Protects against DNS, IPv6, and WebRTC leaks that could expose your real IP address.",
  },
  {
    img: serviceImg,
    name: "Customer Support",
    para: "Boosts speed by blocking ads and trackers, thus reducing load times.",
  },
  {
    img: serviceImg,
    name: "14 Day Free Trial",
    para: "We offer more than 100 servers in 70+ countries, so with NetGuardian, you're global.",
  },
];

export {
  HomeServiceData,
  PricingCardData,
  FeaturesServiceData,
  FeaturesServiceData1,
};
