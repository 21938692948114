import React from 'react'
import Navbarr from '../Components/Navbarr'
import "../assets/css/PrivacyFirst.css"
import PrivacySect from '../Components/PrivacySect'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom'

const PrivacyFirst = () => {
    const Navigate = useNavigate()
    return (
        <div className='privacyFirst'>
            <Navbarr />
            <div className='privacyFirstCont'>
                <div className='privacyFirstCont1'>
                    <p>Privacy</p>
                    <h1>Privacy First Principles</h1>
                </div>
            </div>
            <div className='FirstPC'>
                <div className='privacyFirstContent'>
                    <h2>Dear Valued Users,</h2>
                    <p>We founded NetGuardian with one core mission: to uphold and protect your right to privacy. In an era where digital footprints are constantly scrutinized, our commitment to your privacy is unwavering.</p>
                    <p>Our "Privacy First Policy" is more than just a slogan; it's the ethos that drives every decision we make. We promise a strict no-logging policy, ensuring that your online activities remain private and anonymous. Your data is protected using industry-leading encryption and a variety of secure protocols, offering you the ultimate balance between security and performance.</p>
                    <p>
                        We don't just offer a VPN service; we offer a commitment—a pledge to safeguard your privacy as we navigate the digital world together.<br />
                        Thank you for trusting us with your online privacy.</p>
                    <div className='bottomContentPf'>
                        <p>Warm regards,</p>
                        <p>The Founders of NetGuardian</p>
                        <p>-----</p>
                    </div>
                    <p>Discover more about our commitment to privacy and protect yourself online.</p>
                    <div className='privacyFirstBtn'>
                        <button className='firstPrivacyfbtn' onClick={() => Navigate("/privacy-first")}>Protect My Privacy!</button>
                        <button onClick={() => Navigate("/vpn-features")}>LearnMore</button>
                    </div>
                </div>
            </div>
            <PrivacySect />
            <Footer />
        </div>
    )
}

export default PrivacyFirst;