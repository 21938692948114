import React from 'react'
import LoginImage from "../assets/Images/bro.png"
import "../assets/css/login.css"
import Logo from "../assets/Images/footer logo.png"
import { Link, useNavigate } from 'react-router-dom'
const Login = () => {
    const Navigate = useNavigate()
    return (
        <div className='loginCont'>
            <div className='loginLeft'>
                <img src={LoginImage} alt='Image' />
            </div>
            <div className='loginRight'>
                <img src={Logo} alt='logo' />
                <h1>Login</h1>
                <div className='InputForm'>
                    <div className='loginInput'>
                        <label>EMAIL</label>
                        <input type='email' placeholder='max.mustertmatt@wer.de' />
                    </div>
                    <div className='loginInput'>
                        <label>PASSWORD</label>
                        <input type='password' placeholder='*******' />
                    </div>
                    <button className='signinbtn' onClick={() => Navigate("/")}>Signin</button>
                    <div className='registerlink'>
                        <p>Not a member? <Link className='regLink' to='/signup'>Register Now</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login