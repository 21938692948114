import React from 'react'
import "../assets/css/vpnDownload1.css"
import Navbarr from '../Components/Navbarr'
import androidIcon from "../assets/Images/Frame 1000000827.png"
import iosIcon from "../assets/Images/Frame 1000000826.png"
import windowIcon from "../assets/Images/Group 1000000845.png"
import PricingSection from '../Components/PricingSection'
import Footer from '../Components/Footer'
import PrivacySect from '../Components/PrivacySect'

const VpnDownload1 = () => {
    return (
        <div className='vpnDownload'>
            <Navbarr />
            <div className='vpndownCont1'>
                <div className='downlCont'>
                    <h2>Download</h2>
                    <h1>VPN Downloads</h1>
                    <div className='downloadbtn1'>
                        <div className='downlbtn1'>
                            <img src={androidIcon} alt='icon' className='andImg' />
                            <button>Download</button>
                        </div>
                        <div className='downlbtn1'>
                            <img src={iosIcon} alt='icon' className='iosImg' />
                            <button>Download</button>
                        </div>
                        <div className='downlbtn1'>
                            <img src={windowIcon} alt='icon' className='windImg' />
                            <button>Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <PricingSection />
            <PrivacySect />
            <Footer />
        </div>
    )
}

export default VpnDownload1