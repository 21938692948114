import React from 'react'
import Navbarr from '../Components/Navbarr'
import "../assets/css/vpnFeatures.css"
import { FeaturesServiceData, FeaturesServiceData1 } from './Data'
import FeatureImg2 from "../assets/Images/amico.png"
import PrivacySect from '../Components/PrivacySect'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom'

const VpnFeatures = () => {
    const Navigate = useNavigate()
    return (
        <div className='vpnFeatures'>
            <Navbarr />
            <div className='featuresCont'>
                <div className='featuresMainContent'>
                    <h2>Features</h2>
                    <h1>NetGuardian VPN Features</h1>
                    <p>Designed with Privacy First principles, and packed with powerful <br />features to keep you safe online.</p>
                    <div className='featuresSectbtn'>
                        <button className='featuresbtn1' onClick={() => Navigate("/signup")}>Start Free Trial!</button>
                        <button onClick={() => Navigate("/vpn-features")}>Learn More</button>
                    </div>
                </div>
            </div>
            <div className='homeServiceSect'>
                <div className='servicesTop1'>
                    <div className='serviceTopleft1'>
                        <h2>Features</h2>
                        <h1>Packed with powerful features.</h1>
                    </div>
                </div>
                <div className='servicesBottom1'>
                    {FeaturesServiceData.map((item, index) => (
                        <div className='servicesCards1' key={index}>
                            <img src={item.img} alt='cloud icon' />
                            <h1>{item.name}</h1>
                            <p>{item.para}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='homesect3'>
                <div className='sect3left'>
                    <img src={FeatureImg2} alt='img' />
                </div>
                <div className='sect3Right'>
                    <h1>Wordwide blazing fast server locations</h1>
                    <p>Our solution empowers you to secure your data, safeguard your online activities, and take control of your privacy on the web. Get ready to experience peace of mind in the digital world.</p>
                    <button onClick={() => Navigate("/signup")}>Start Free Trial!</button>
                </div>
            </div>
            <div className='servicesBottom1'>
                {FeaturesServiceData1.map((item, index) => (
                    <div className='servicesCards1' key={index}>
                        <img src={item.img} alt='cloud icon' />
                        <h1>{item.name}</h1>
                        <p>{item.para}</p>
                    </div>
                ))}
            </div>
            <PrivacySect />
            <Footer />
        </div>
    )
}

export default VpnFeatures