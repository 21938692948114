import React from 'react'
import "../assets/css/privacysect.css"
import { useNavigate } from 'react-router-dom'

const PrivacySect = () => {
  const Navigate = useNavigate()
  return (
    <div className='privacySect'>
      <h1>Ready to Ensure Your Online Privacy?</h1>
      <p>Our solution empowers you to secure your data, safeguard your online activities, and take control <br /> of your privacy on the web. Get ready to experience peace of mind in the digital world.</p>
      <button onClick={() => Navigate("/signup")}>Start Free Trial!</button>
    </div>
  )
}

export default PrivacySect