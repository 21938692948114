import React from "react";
import FooterLogo from "../assets/Images/icontopblack.svg";
import "../assets/css/footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footerleft">
          <img src={FooterLogo} alt="footer logo" />
        </div>
        <div className="footerRight">
          <div className="footerlinks1">
            <h2>Useful Links</h2>
            <ul>
              <li>Sign Up</li>
              <li>Whats a VPN?</li>
              <li>Features</li>
              <li>Privacy Policy</li>
              <li>Terms of Service</li>
            </ul>
          </div>
          <div className="footerlinks1">
            <h2>ABOUT</h2>
            <ul>
              <li>Our Privacy First Commitment</li>
            </ul>
          </div>
          <div className="footerlinks1">
            <h2>Follow Us</h2>
            <ul>
              <li>Facebook</li>
              <li>Twitter</li>
              <li>Instagram</li>
              <li>Linkedin</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footerCopyright">
        <p>Copyright © 2023 all rights reserved Netguardian</p>
      </div>
    </>
  );
};

export default Footer;
