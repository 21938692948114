import React from "react";
import Navbarr from "../Components/Navbarr";
import "../assets/css/home.css";
import HomeshapImage from "../assets/Images/homeHeroShape.png";
import HomeHeroImage from "../assets/Images/rocket.svg";
import HomeImg1 from "../assets/Images/Group 31.png";
import { HomeServiceData } from "./Data";
import HomeImg2 from "../assets/Images/Online world-bro.svg";
import HomeImg3 from "../assets/Images/Private data-bro.svg";
import HomeImg4 from "../assets/Images/Cross-platform software-bro.svg";
import PricingSection from "../Components/PricingSection";
import PrivacySect from "../Components/PrivacySect";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const Navigate = useNavigate();
  return (
    <div>
      <Navbarr />
      <div className="homeCont">
        <div className="homeContainer">
          <div className="homeCont-left">
            <div className="homeContleftContent">
              <p>Secure your online activity with our</p>
              <h1>Fast & Private VPN</h1>
              <p className="homeCont-leftPara">
                Unlock the ultimate online protection and enjoy seamless
                browsing without compromising on speed or security.
              </p>
              <div className="homeHerobtn">
                <button
                  className="freeTrialbtn"
                  onClick={() => Navigate("/signup")}
                >
                  Start Free Trial!
                </button>
                <button
                  className="learnMorebtn"
                  onClick={() => Navigate("/privacy-first")}
                >
                  Learn More
                </button>
              </div>
              <div className="homeHeroTimer">
                <p className="homeCont-leftPara">Hurry! Offer ends in:</p>
                <div className="timersect">
                  <div className="Timesect1">
                    <h1>00 :</h1>
                    <p>HH</p>
                  </div>
                  <div className="Timesect1">
                    <h1>00 :</h1>
                    <p>MM</p>
                  </div>
                  <div className="Timesect1">
                    <h1>00 </h1>
                    <p>SS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homeCont-right">
            <img
              src={HomeshapImage}
              alt="hero shape image"
              className="heroshapeImage"
            />
            <img
              src={HomeHeroImage}
              alt="hero sect image"
              className="heroSectImage"
            />
          </div>
        </div>
        <img src={HomeImg1} alt="image" className="homeImg1" />
      </div>
      <div className="homeServiceSect">
        <div className="servicesTop">
          <div className="serviceTopleft">
            <h2>Learn how we protect your privacy</h2>
            <h1>Ever wonder how a VPN secures your internet life?</h1>
          </div>
          <div className="serviceTopRight">
            <p>
              Discover how a VPN encrypts your online activities and safeguards
              your digital privacy, offering a shield against cyber threats.
            </p>
            <button onClick={() => Navigate("/vpn-features")}>
              Learn more
            </button>
            <button onClick={() => Navigate("/vpn-features")}>
              Get it now
            </button>
          </div>
        </div>
        <div className="servicesBottom">
          {HomeServiceData.map((item, index) => (
            <div className="servicesCards" key={index}>
              <img src={item.img} alt="cloud icon" />
              <h1>{item.name}</h1>
              <p>{item.para}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="homesect3">
        <div className="sect3left">
          <img src={HomeImg2} alt="img" />
        </div>
        <div className="sect3Right">
          <h1>Wordwide blazing fast server locations</h1>
          <p>
            Our global server locations provide blazing fast upload and download
            speeds, no matter where you are located. Appear online as if you are
            in another country, bypassing georestrictions and IP blocks.
          </p>
          <button onClick={() => Navigate("/signup")}>Start Free Trial!</button>
        </div>
      </div>
      <div className="homesect4">
        <div className="homesect4left">
          <h2>Security and Privacy</h2>
          <h1>
            We are committed to keeping your data safe, private and secure.
          </h1>
          <p>
            At Netguardian, we prioritize your data's safety, privacy, and
            security. Using advanced encryption and strict security measures, we
            protect your information diligently, offering you peace of mind.
          </p>
          <button onClick={() => Navigate("/signup")}>Start Free Trial!</button>
        </div>
        <div className="homesect4Right">
          <img src={HomeImg3} alt="img" />
        </div>
      </div>
      <div className="homesect4-1">
        <div className="homesect4Right">
          <img src={HomeImg4} alt="img" />
        </div>
        <div className="homesect4left">
          <h2>Cross Platform Support</h2>
          <h1>Available on all of your devices!</h1>
          <p>
            Enjoy seamless access across all your devices. Our platform is
            designed for universal compatibility, ensuring you can stay safe,
            connected and private, no matter where you are or what device you're
            using.
          </p>
          <button onClick={() => Navigate("/signup")}>Start Free Trial!</button>
        </div>
      </div>
      <PricingSection />
      <PrivacySect />
      <Footer />
    </div>
  );
};

export default Home;
